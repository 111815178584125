import { type MetaFunction } from '@remix-run/node'
import Home from './home'

export const meta: MetaFunction = () => [{ title: 'Ramona Ridgewell - Author' }]

const Index = () => {

	return (
		<Home />
	)
}

export default Index
